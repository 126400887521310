import '../../styles/pages/spring-summer-2024/a-home-behind-the-lens.scss';
import React from "react";
import Layout from '../../components/layout';
import { Helmet } from "react-helmet"
import ScrollAnimation from 'react-animate-on-scroll';
import MoreStories from "../../components/spring-summer-2024-stories/more-stories"
import Gallery from "react-photo-gallery";
import Vimeo from '@u-wave/react-vimeo';

import Hero from '../../images/spring-summer-2024/a-home-behind-the-lens/a-home-behind-the-lens-hero-1920x1080.jpg';
import socialBanner from "../../images/spring-summer-2024/social-banners/a-home-behind-the-lens-social-banners-1200x630.jpg";
import titelGraphic from "../../images/spring-summer-2024/a-home-behind-the-lens/a-home-behind-the-lens-title-380x245.svg";
import ctaBanner from '../../images/spring-summer-2024/a-home-behind-the-lens/a-home-behind-the-lens-cta-banner-1920x515.jpg';

import ahomebehindthelensIMG1 from '../../images/spring-summer-2024/a-home-behind-the-lens/a-home-behind-the-lens-image-1-533x800.jpg';
import ahomebehindthelensIMG2 from '../../images/spring-summer-2024/a-home-behind-the-lens/a-home-behind-the-lens-image-2-533x800.jpg';
import ahomebehindthelensIMG3 from '../../images/spring-summer-2024/a-home-behind-the-lens/a-home-behind-the-lens-image-1-800x533.jpg';
import ahomebehindthelensIMG4 from '../../images/spring-summer-2024/a-home-behind-the-lens/a-home-behind-the-lens-image-2-800x533.jpg';
import ahomebehindthelensIMG5 from '../../images/spring-summer-2024/a-home-behind-the-lens/a-home-behind-the-lens-image-3-800x533.jpg';

export default class Index extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
    };
  }

  render() {
    var pageTitle = 'Communications Alumna Wins Pulitzer Prize for Photography';
    var pageDesc = 'From the Daily Titan to the Los Angeles Times, CSUF alumna Christina House wins a coveted Pulitzer Prize for feature photography.';
    var slug = 'a-home-behind-the-lens';

    var heroStyles = {
      backgroundImage: `url(${Hero})`,
    }

    const photos = [
      {
        src: ahomebehindthelensIMG3,
        width: 800,
        height: 533,
        alt: '',
        title: ''
      },
      {
        src: ahomebehindthelensIMG4,
        width: 800,
        height: 533,
        alt: '',
        title: ''
      },

    ];

    return (
      <Layout headerStyle="white" locationInfo={this.props.location} issue="spring-summer-2024">
        <Helmet>
          <title>{pageTitle}</title>
          <body className={`spring-summer-2024 ${slug}`} />

          <meta name="description" content={pageDesc} />

          {/* Schema.org markup for Google+ */}
          <meta itemprop="name" content={pageTitle} />
          <meta itemprop="description" content={pageDesc} />
          <meta itemprop="image" content={socialBanner} />

          {/* Twitter Card data */}
          <meta name="twitter:title" content={pageTitle} />
          <meta name="twitter:description" content={pageDesc} />
          <meta name="twitter:image:src" content={socialBanner} />

          {/* Open Graph data */}
          <meta property="og:title" content={pageTitle} />
          <meta property="og:image" content={socialBanner} />
          <meta property="og:image:alt" content={pageTitle} />
          <meta property="og:description" content={pageDesc} />
        </Helmet>
        
        <div className="hero story animated fadeIn" style={heroStyles}>
          <div className="text">
            <h1 className="animated fadeInDown delay-1s"><img className='titelGraphic shadow' src={titelGraphic} alt='A Home Behind the Lens' /></h1>
            <p className="sub-heading animated fadeInDown delay-1s">From the Daily Titan to the Los Angeles Times, a CSUF alumna wins a coveted Pulitzer Prize for feature photography.
</p>
            <span className="animated fadeInDown delay-1s byline">By Taylor Arrey / Photos by Matt Gush</span>
          </div>
          {/* <div className="tint"></div> */}
        </div>
        <article className='wrap' id="copy-wrap">

<div className='wrap small'>

<figure className='align-right large align-outside'>
            <img src={ahomebehindthelensIMG1} alt="" />
  <figcaption>Christina House, CSUF alumna and L.A. Times photographer (Matt Gush/CSUF)</figcaption>
</figure>
<p><span className="intro-text">Each time Christina House </span> and her mother would visit family in the Philippines, saying goodbye was the hardest part.</p>
<p>&ldquo;It would be this very devastating 24 hours of mourning before we got on the plane,&rdquo; she says. &ldquo;It was painful to live so far apart, and it would sometimes be years before we could afford to come back.&rdquo;</p>
<p>House needed a way to preserve those memories &mdash; for herself and her mother.</p>
<p>She found her answer behind the lens.</p>
<p>&ldquo;I always took pictures and when I&rsquo;d go back to the Philippines, I would bring prints and give everyone a copy. It was my way of staying in touch,&rdquo; says House.</p>
<p>She followed her first love &mdash; photography &mdash; to Cal State Fullerton before going on to become a Pulitzer Prize-winning photojournalist at the Los Angeles Times.</p>
<p>&ldquo;The solid foundation that I built at Cal State Fullerton is incredible, and I&rsquo;m very proud to be a Titan,&rdquo; says House &rsquo;06 (B.A. communications-photocommunications).</p>
<figure className='align-left large align-outside'>
            <img src={ahomebehindthelensIMG2} alt="" />
  <figcaption>L.A. Times print issue from “Hollywood’s Finest” project (Courtesy of Los Angeles Times)</figcaption>
</figure>
<h2>Winning a Pulitzer Prize</h2>
<p>When House got the call from her editor at the L.A. Times that she was being assigned to &ldquo;<a href="https://www.latimes.com/california/story/2022-07-13/hollywoods-finest-series-pregnancy-homelessness-los-angeles" title="Hollywood’s Finest" target="_blank">Hollywood&rsquo;s Finest</a>,&rdquo; she says, &ldquo;it was quite a whirlwind.&rdquo; The piece tells the story of Mckenzie Trahan, a 22-year-old pregnant woman living on Hollywood&rsquo;s streets.</p>
<p>&ldquo;I had just found out that I was pregnant myself, but it was really early,&rdquo; says House. &ldquo;I think it gave me more perspective. It&rsquo;s a story about motherhood above all. And the biggest takeaway is that every birth is important regardless of the circumstances. I came to a deep understanding of that by witnessing Mckenzie&rsquo;s journey alongside my own.&rdquo;</p>
<p>She started working on the photography for the project in 2018 and stayed with it until its publication in 2022. During that time, House accompanied Trahan throughout her pregnancy, photographing her life on the streets, her visits to the hospital, and the birth of her baby.</p>
<p>&ldquo;We followed her along her journey to find housing, give birth to her child and then ultimately not be able to keep her child &mdash; and the devastation that comes with that,&rdquo; explains House. &ldquo;I think anyone who reads this should have a little more grace the next time they pass by a homeless encampment.&rdquo;</p>
<figure className='align-right large align-outside'>
            <img src={ahomebehindthelensIMG3} alt="" />
  <figcaption>Christina House with “Hollywood’s Finest” videographer Claire Hannah Collins after winning the Pulitzer Prize (Courtesy of Genaro Molina/Los Angeles Times) </figcaption>
</figure>	
<p>In 2023, House won a Pulitzer Prize for feature photography for her work on the project.</p>
<p>When she heard she won the award, she was in complete shock. The highest national honor in print journalism, literary achievement, and musical composition, House says she&rsquo;ll never forget accepting the award in front of her family, including her child and husband, Wally Skalij, who is a Los Angeles Times photojournalist.</p>
<p>&ldquo;It was touching that the Pulitzer board saw the intimacy and how the story was told and wanted to award the piece for that,&rdquo; says House. &ldquo;I&rsquo;m also happy because it&rsquo;ll get more eyes on this story and more people will have the chance to learn about Mckenzie and her life.&rdquo;</p>
<figure className='align-wide'>
            <img src={ahomebehindthelensIMG4} alt="" />
  <figcaption>House accepts the Pulitzer Prize for feature photography from Lee Bollinger, Columbia University president emeritus (Courtesy of Diane Bondareff/The Pulitzer Prizes) </figcaption>
</figure>
<h2>A Vision for the Future</h2>
<p>House always loved visual storytelling, but she wasn&rsquo;t sure how to turn that passion into a career.</p>
<p>It was Mark Boster &rsquo;76 (B.A. communications) who helped her find her way. The Titan alumnus and former lecturer in communications worked as a photojournalist at the L.A. Times for over 30 years. In 2016, his photos and videos were part of the Pulitzer Prize-winning package that covered the San Bernardino terrorist shooting. He also covered the 1984 Summer Olympics and multiple presidential elections, using every story to teach his students about the photojournalism industry.</p>

<blockquote className='align-left large align-outside'>
  <p>I couldn’t imagine a life without taking pictures&nbsp;”</p>
              <cite>Christina House ’06</cite>
</blockquote>
<p>&ldquo;I didn&rsquo;t know what photojournalism was until I was at Cal State Fullerton,&rdquo; says House. &ldquo;Boster brought his real-world experiences into the classroom, and that really opened up my mind to the possibility of doing this type of work.&rdquo;</p>
<p>She got her start as a freelance photographer and later photo editor for CSUF&rsquo;s student-run newspaper, the Daily Titan. Housed in the <a href="https://communications.fullerton.edu/" title="College of Communications" target="_blank">College of Communications</a>, the award-winning publication provides students hands-on journalism experience.</p>
<p>Managing the photo desk at the Daily Titan taught her how to meet deadlines, collaborate with a team, build healthy working relationships and succeed in a fast-paced news environment.</p>
<p>&ldquo;The relationships I built within the newsroom, I am still able to access. Those friends and their skills helped me fine-tune my work as I was continuing to learn and grow out in the real world,&rdquo; adds House, who transferred to CSUF from Long Beach City College.</p>
<p>A year after graduating from CSUF, the L.A. Times offered House a freelance contract. As a freelancer, she worked on a myriad of projects, capturing photos for feature and investigative stories and celebrity portraits. She was offered a full-time staff photojournalist position in 2017.</p>
<p>&ldquo;She had everything she needed to be great,&rdquo; says Davis Barber, CSUF lecturer in communications. &ldquo;It&rsquo;s been a joy to watch her grow from an eager young student in my photojournalism class to the world-class photographer she is now.&rdquo;</p>
<figure className='align-wide'>
            <img src={ahomebehindthelensIMG5} alt="" />
            <figcaption>Pulitzer Prize-winning photographer Christina House at the Los Angeles Times office in El Segundo (Matt Gush/CSUF)</figcaption>
</figure>
<p>This spring, House was inducted into the Distinguished Alumni Wall of Fame for Cal State Fullerton’s Department of Communications.</p>
<p>Throughout her career, House has held tight to her love for storytelling. She has filmed unique stories like Pat Manuel, the first transgender male boxer to fight professionally in the U.S., and photographed portrait series on female athletes from across the country and the 101 most powerful people in Los Angeles according to the L.A. Times.</p>
<p>&ldquo;I&rsquo;m really proud that I didn&rsquo;t give up,&rdquo; says House. &ldquo;I couldn&rsquo;t imagine a life without taking pictures. I really think that you thrive when you honor your soul and follow the path of your heart. Photography was one of my first loves, and I don&rsquo;t think I would be able to live with myself if I didn&rsquo;t give myself a real shot at it.&rdquo; <span className="end-mark" /></p>

</div>
        </article>
        <div className='cta-banner' style={{ backgroundImage: `url(${ctaBanner})` }}>
          <div className='cta-text'>
            <p>
              <span className='cta-title'>Support the College of Communications</span><br />
              <a href="https://give.fullerton.edu/ccom" target='_blank' rel='noreferrer'>give.fullerton.edu/ccom</a>
            </p> 
          </div>
        </div>
        <MoreStories slug={slug} />
      </Layout>
    );
  }
} 